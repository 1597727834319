import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';
import { EXHIBITOR_BASE_FRAGMENT } from '@/graphql/_Fragments/Exhibitor/Base';
import { SPEAKER_ROLE_FOR_SESSION_FRAGMENT } from '@/graphql/_Fragments/SpeakerRole/ForSession';
import { CATEGORY_WITH_CONTEXT_FRAGMENT } from '@/graphql/_Fragments/Category/WithContext';
import { GEOZONE_WITH_EXHIBIT_HALL_FRAGMENT } from '@/graphql/_Fragments/Geozone/WithExhibitHall';

export const SESSION_CARD_FRAGMENT = `
  fragment sessionCardFragment on Session {
    uid
    id
    schemaCode
    name
    startTime
    endTime
    featured
    imageFileResource {
      ...fileResourceBaseFragment
    }
    bannerFileResource {
      ...fileResourceBaseFragment
    }
    exhibitor {
      ...exhibitorBaseFragment
    }
    categoriesInContext(context:"session_flag") {
      ...categoryWithContextFragment
    }
    speakerRoles {
      ...speakerRoleForSessionFragment
    }
    _actions(actions: ["CAN_BOOKMARK"]) {
      value
      key
    }
    geozones: _geozonesOfEdition {
      ...geozoneWithExhibitHallFragment
    }
    _bookmarkCount
    _isBookmarked(myUid: "%authUser%")
    _isInAgenda(myUid: "%authUser%")
    _isRecommendedForMe
  }
  ${CATEGORY_WITH_CONTEXT_FRAGMENT}
  ${EXHIBITOR_BASE_FRAGMENT}
  ${FILE_RESOURCE_BASE_FRAGMENT}
  ${SPEAKER_ROLE_FOR_SESSION_FRAGMENT}
  ${GEOZONE_WITH_EXHIBIT_HALL_FRAGMENT}
`;
